import Vuex from 'vuex'
import Vue from "vue";
import {get, getWithId} from "../request.js";
import ui from "./ui.js";
import {getCategoryPath} from "../../utils/category-utils.js";
const {MATT_CONTENT_API, MATT_USER_API} = window;

Vue.use(Vuex);

export default {
  namespaced: true,
  modules: { ui },
  state: {
    rootCategory: null,
    allCategories: [],
    categoryPath: '/',
    category: null,
    categoryResources: [],
    subcategories: [],
    resource: null,
    categoryProgress: null
  },
  mutations: {
    setAllCategories(state, items) {
      state.allCategories = items;
    },
    setCategory(state, category) {
      state.category = category;
    },
    setCategoryPath(state, path) {
      state.categoryPath = path;
    },
    setCategoryResources(state, resources) {
      state.categoryResources = resources;
    },
    setSubcategories(state, subcategories) {
      state.subcategories = subcategories;
    },
    setRootCategory(state, rootCategory) {
      state.rootCategory = rootCategory;
    },
    setCategoryProgress(state, categoryProgress) {
      state.categoryProgress = categoryProgress;
    },
    reset(state) {
      state.rootCategory = null;
      state.allCategories = [];
      state.categoryPath = '/';
      state.category = null;
      state.categoryResources = [];
      state.subcategories = [];
      state.resource = null;
      state.categoryProgress = null;
    }
  },
  getters: {
    hasResources: (state) => {
      return !state.allCategories.find(item => item.path === state.categoryPath);
    }
  },
  actions: {
    async loadIndex({commit, dispatch}, slug) {
      if(!slug) {
        return;
      }

      await dispatch('ui/setLoading', 'Loading index...');

      try {
        commit('reset');

        const url = `${MATT_CONTENT_API}/category/${slug}`;
        const response = await getWithId(this._vm.$auth, url);
        const category = response.data.category;
        const allCategories = response.data.categories;
        const rootPath = getCategoryPath(category);
        const subcategories = allCategories.filter(item => item.path === rootPath);

        commit('setAllCategories', allCategories);
        commit('setRootCategory', category);
        commit('setCategory', category);
        commit('setCategoryPath', "/");
        commit('setSubcategories', subcategories);
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async loadCategory({commit, state, dispatch}, categoryPath) {

      await dispatch('ui/setLoading', 'Loading...');

      // index is not loaded yet...
      if(!state.rootCategory?.slug) {
        return;
      }

      try {
        let path = categoryPath;
        if (!categoryPath || categoryPath === '/') {
          path = getCategoryPath(state.rootCategory);
        }

        const category = state.allCategories.find(item => `${item.path}/${item.id.substring(0, 8)}`.replace('//', '/') === path);
        const subcategories = state.allCategories.filter(item => item.path === path);

        commit('setCategory', category);
        commit('setCategoryPath', path);
        commit('setSubcategories', subcategories);

        // does this category have resources OR subcategories?
        if (subcategories.length > 0) {
          commit('setCategoryResources', []);
        } else {
          let targetCat = category?.slug || state.rootCategory?.slug;
          await dispatch(`loadResourcesForCategory`, targetCat);
        }
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async loadResourcesForCategory({commit, dispatch}, categorySlug) {
      await dispatch('ui/setLoading', 'Loading resources...');

      try {
        const response = await get(this._vm.$auth, `${MATT_CONTENT_API}/resources/index/slug/${categorySlug}`);
        commit('setCategoryResources', response.data.pages);
        return response.data;
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async loadCategoryProgress({commit, state, dispatch}) {
      await dispatch('ui/setLoading', 'Loading progress...');

      try {
        const resourceIds = state.categoryResources.map(r => r.id).join(',');
        const params = {resourceIds};
        const response = await getWithId(this._vm.$auth, `${MATT_USER_API}/report/resources/myProgress`, {params});
        commit('setCategoryProgress', response.data);
        return response.data;
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
  },
}