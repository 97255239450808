<template>
  <div class="ma-content" v-if="!loading">
    <v-row v-if="showDiagnosticCard">
      <v-col cols="12" md="12"><DiagnosticCard /></v-col>
    </v-row>
    <v-row v-if="showWelcomeCard">
      <v-col cols="12" md="6"><ContentCard :card="welcomeCard" /></v-col>
      <v-col cols="12" md="6"><ContentCard :card="whereToStartCard" /></v-col>
    </v-row>
    <v-row v-if="showWhatNextCard">
      <v-col cols="12" md="12"><WhatNextCard /></v-col>
    </v-row>
    <v-row v-if="showNewsCards">
      <v-col cols="12" md="6"><ContentCard :card="musicNewsCard" /></v-col>
      <v-col cols="12" md="6">
        <ContentCard :card="studentNewsCard" v-if="!teacherNewsCard" />
        <ContentCard :card="teacherNewsCard" v-if="teacherNewsCard" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6"><TaskSummaryCard /></v-col>
      <v-col cols="12" md="6"><WordOfTheDayCard /></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12"><StrengthsAndWeaknessesCard /></v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import StrengthsAndWeaknessesCard from "./StrengthsAndWeaknessesCard.vue";
import TaskSummaryCard from "./TaskSummaryCard.vue";
import WordOfTheDayCard from "./WordOfTheDayCard.vue";
import ContentCard from "../../resource/card/ContentCard.vue";
import WhatNextCard from "./WhatNextCard.vue";
import DiagnosticCard from "./DiagnosticCard.vue";

export default {
  name: "StudentHome",
  components: {DiagnosticCard, WhatNextCard, WordOfTheDayCard, ContentCard, TaskSummaryCard, StrengthsAndWeaknessesCard},
  computed: {
    ...mapState('dashboard', ['data']),
    loading() {
      return !this.data;
    },
    showDiagnosticCard() {
      return this.data.diagnostic?.visible;
    },
    showWelcomeCard() {
      return this.welcomeCard && this.whereToStartCard;
    },
    showNewsCards() {
      return this.studentNewsCard && this.musicNewsCard;
    },
    showWhatNextCard() {
      return this.data.whatNext?.visible;
    },
    welcomeCard() {
      return this.data.cards.welcome;
    },
    whereToStartCard() {
      return this.data.cards.whereToStart;
    },
    studentNewsCard() {
      return this.data.cards.studentNews
    },
    teacherNewsCard() {
      return this.data.cards.teacherNews;
    },
    musicNewsCard() {
      return this.data.cards.musicNews;
    },
  },
  methods: {
    ...mapActions('ui', ['resetBreadcrumbs']),
    ...mapActions('dashboard', ['loadData']),
  },
}
</script>

<style lang="scss">
@import '../style';

$block-title-color: lighten($col-home, 60%);

.ma-home {
  @include withTitleBlock($col-home);
}

</style>