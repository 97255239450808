export const COLOURS = {
  MA_CYAN: '#00bcd4',
  MA_PURPLE: '#4f347c',
  MA_DARK_BLUE: '#2c5e8b',
  GRADUS_SALMON: '#ff6d6d',
  PASSED: '#008000',
  PASSED_OVERDUE: 'yellowgreen',
  FAILED: '#aa0000',
  OVERDUE: '#FFA500',
  NOT_ATTEMPTED: '#aaa',
};

export const TASK_STATUS = {
  PASSED: 'Passed',
  PASSED_OVERDUE: 'Passed (overdue)',
  FAILED: 'Failed',
  OVERDUE: 'Overdue',
  NOT_ATTEMPTED: 'Not attempted',
};

export const DATE_RANGES = {
  ONE_DAY: { value: 1, label: '24hrs' },
  ONE_WEEK: { value: 7, label: '1 week' },
  TWO_WEEKS: { value: 14, label: '2 weeks' },
  ONE_MONTH: { value: 30, label: '1 month' },
  THREE_MONTHS: { value: 91, label: '3 months' },
  ONE_YEAR: { value: 365, label: '1 year' },
};

export const DATE_RANGE_OPTIONS = Object.values(DATE_RANGES);

export const COMPLETION_TYPES = {
  DIAGNOSTIC: 'diagnostic',
  MANUAL: 'manual',
  FAIL: 'fail',
  VIRTUAL: 'virtual',
  FULL: 'full'
};

export const RESOURCE_TYPES = {
  PATHWAY: 'Pathway',
  SECTION: 'Section',
  MODULE: 'Module',
  PAGE: 'Page',
  QUIZ: 'Quiz',
}

export const QUIZ_TYPES = {
  GRADED: 'graded',
  PRACTICE: 'practice',
  DIAGNOSTIC: 'diagnostic',
  TUTORIAL: 'tutorial',
};

export const QUIZ_TYPE_OPTIONS = [
    {label: 'Graded', value: QUIZ_TYPES.GRADED},
    {label: 'Practice', value: QUIZ_TYPES.PRACTICE},
    {label: 'Diagnostic', value: QUIZ_TYPES.DIAGNOSTIC},
    {label: 'Tutorial', value: QUIZ_TYPES.TUTORIAL},
];

export const STRENGTH_LEVELS = [
  'Very strong',
  'Strong',
  'Neutral',
  'Weak',
  'Very weak',
];

export const ICONS = {
  HOME: 'mdi-home',
  DASHBOARD: 'mdi-view-dashboard',
  PATHWAY: 'mdi-arrow-decision',
  MODULE: 'mdi-book-open-page-variant',
  RESOURCE: 'mdi-file-document-outline',
  QUIZ: 'mdi-file-question-outline',
  RESULTS: 'mdi-clipboard-check-outline',
  TASKS: 'mdi-clipboard-clock-outline',
  PROFILE: 'mdi-account-outline',
  TEACHER: 'mdi-account-tie-outline',
  TEACHER_ALT: 'mdi-account-tie',
  FEEDBACK: 'mdi-comment-quote',
  DIAGNOSTIC: 'mdi-fast-forward',
  VOICE: 'mdi-account-voice',
  PAUSE: 'mdi-pause',
};

export const AWARDS = {
  GOLD: 'Gold',
  SILVER: 'Silver',
  BRONZE: 'Bronze',
  NONE: 'None',
};

export const PASS_SCORE = 51;
export const DIAGNOSTIC_QUESTION_PASS_MARK = 50;

export const FEEDBACK_TYPES = [
  'Question',
  'General feedback',
  'Bug report',
  'Feature request',
];

export const LOCAL_STORAGE_KEYS = {
  TEACHER_DASHBOARD_DAYS: 'teacherDashboard.days',
  TEACHER_DASHBOARD_COHORT_ID: 'teacherDashboard.cohortId',
};

export const Icons = {
  "home": "mdi-home",
  "dashboard": "mdi-view-dashboard",
  "pathway": "mdi-arrow-decision",
  "module": "mdi-book-open-page-variant",
  "resource": "mdi-file-document-outline",
  "quiz": "mdi-file-question-outline",
  "results": "mdi-clipboard-check-outline",
  "page": "mdi-file-document-outline",
  "syllabus": "mdi-file-document-outline",
  "tasks": "mdi-clipboard-clock-outline",
  "profile": "mdi-account-outline",
  "glossary": "mdi-book-open-page-variant",
  "help": "mdi-help-circle-outline",
  "teacher": "mdi-account-tie-outline",
  "teacher-alt": "mdi-account-tie",
  "complete": "mdi-check-circle",
  "feedback": "mdi-comment-quote",
  "diagnostic": "mdi-fast-forward",
}
