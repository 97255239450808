<template>
  <v-card elevation="5" @click="navigateToTasks">
    <v-card-title>
      <h2>Tasks</h2>
    </v-card-title>
    <v-card-text>
      <div v-if="!hasTeacher">
        <p>You don't have a teacher added yet!</p>
        <p>Ask your teacher for their unique teacher code, once you've added it to your account they can start assigning tasks to you.</p>
      </div>
      <div v-if="hasTeacher && !current">
        <p>You don't have tasks to complete!</p>
        <p>There's still plenty to do. Why not browse through some reading materials, or try some tutorials, or do a few practice quizzes?</p>
      </div>
      <p v-if="current">You've got <strong>{{ current }}</strong> task(s) that need to be completed!</p>
      <p v-if="overdue"><strong>{{ overdue }}</strong> of those tasks are overdue!</p>
      <span class="ma-help-text pa-2" v-if="current">Click on this card to view your tasks...</span>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "TaskSummaryCard",
  computed: {
    ...mapState('dashboard', ['data', 'noobMode']),
    summary() {
      return this.data.tasks.summary;
    },
    overdue() {
      return this.summary.overdue;
    },
    current() {
      return this.summary.current;
    },
    hasTeacher() {
      return this.data.tasks.hasTeacher;
    },
  },
  methods: {
    navigateToTasks() {
      this.$router.push({name: 'tasks'});
    }
  }
}
</script>
