import {getWithId} from "../request.js";

export default {
  namespaced: true,
  state: {
    speechUrl: null,
    speechState: 'stopped'
  },
  mutations: {
    updateSpeechUrl(state, url) {
      state.speechUrl = url;
    },
    updateSpeechState(state, newState) {
      state.speechState = newState;
    }
  },
  actions: {
    async generateAndPlaySpeech({commit, dispatch}, { resourceId, field, options }) {
      commit('updateSpeechState', `loading|${resourceId}|${field}`);

      const url = `${window.MATT_URL}/ai/v1/generate-tts/${resourceId}/${field}`;
      const response = await getWithId(this._vm.$auth, url, {params: options});
      const speechUrl = response.data.speechUrl;

      await dispatch('playSpeech', {speechUrl, resourceId, field});
    },
    async testSpeech({commit, dispatch}, text) {
      commit('updateSpeechState', `loading|test|test`);

      const url = `${window.MATT_URL}/ai/v1/test-tts?text=${text}`;
      const response = await getWithId(this._vm.$auth, url);
      const speechUrl = response.data.speechUrl;

      await dispatch('playSpeech', {speechUrl, resourceId: 'test', field: 'test'});
    },
    async playSpeech({commit}, {speechUrl, resourceId, field}) {
      commit('updateSpeechUrl', speechUrl);
      commit('updateSpeechState', `playing|${resourceId}|${field}`);
    },
    stopSpeech({commit}) {
      commit('updateSpeechState', 'stopped');
      commit('updateSpeechUrl', null);
    },
    pauseSpeech({commit}) {
      commit('updateSpeechState', 'paused');
    }
  }
}