<template>
  <div>
    <p class="text-sm-h3 text-h5 font-weight-bold cyan--text">
      Welcome to {{ appTitle }}
    </p>
    <p class="text-body2 text-break" style="max-width: 30rem">
      <span class="font-weight-bold">MusicApprentice</span> is a new and effective, curriculum-aligned, teaching aid for classroom and private studio music teachers.
    </p>
    <p class="text-body2 text-break" style="max-width: 35rem">
      MusicApprentice’s vibrant online learning platform engages students, supports quality learning and teacher-led instruction, and effective integration into the classroom and studio environment - the result of an
      ongoing, teacher-led development process.
    </p>
    <p class="text-body2 text-break" style="max-width: 31rem">
      Discover for yourself why MusicApprentice is the ultimate online resource for active listening and music literacy.
    </p>
    <p class="text-body2 text-break" style="max-width: 31rem">
      For more information about MusicApprentice and how to purchase a subscription, visit <a href="http://gradussoftware.com.au/" target="_blank" rel="noopener">http://gradussoftware.com.au</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: "WelcomeText",
  props: {
    appTitle: {
      type: String,
      required: true
    }
  }
}
</script>