<template>
  <v-card elevation="5" v-if="data.whatNext">
    <v-card-title>
      <h2 @click="navigateToModule" style="cursor:pointer;">What next - {{ nextModule.title }}</h2>
      <SpeechPlayerController :resource="nextModule" field="description" />
    </v-card-title>
    <v-card-text>
      <div v-html="nextModule.description"></div>
      <div>
        <span v-if="tutorials.length>0">
          The tutorial <a v-for="(q, idx) in tutorials" :key="`tutorial-${idx}`" @click="navigateToQuiz(q)">{{ q.title }}</a> will help you get started.
        </span>
        <span v-if="practice.length>0">
          Try the practice <span v-if="practice.length>1">quizzes</span><span v-if="practice.length===1">quizzes</span>
          <span v-for="(q, idx) in practice" :key="`practice-${idx}`">
            <a @click="navigateToQuiz(q)">
              {{ q.title }}
              <span v-if="q.metadata && q.metadata.level">(Level {{ q.metadata.level }})</span>
            </a>
            <span v-if="idx < practice.length-2">, </span>
            <span v-if="idx === practice.length-2"> or </span>
          </span>
          to test your knowledge before attempting the graded quizzes.
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapState} from "vuex";
import SpeechPlayerController from "../../common/SpeechPlayerController.vue";

export default {
  name: "WhatNextCard",
  components: {SpeechPlayerController},
  computed: {
    ...mapState('dashboard', ['data']),
    nextModule() {
      return this.data.whatNext.module;
    },
    quiz() {
      return this.data.whatNext.quiz;
    },
    tutorials() {
      return this.data.whatNext.tutorials;
    },
    practice() {
      return this.data.whatNext.practice;
    },
    resources() {
      return [...this.tutorials, ...this.practice];
    },
    pathwaySlug() {
      return this.data.whatNext.pathway.slug;
    },
    moduleSlug() {
      return this.data.whatNext.module.slug;
    },
  },
  methods: {
    navigateToQuiz(quiz) {
      this.$router.push({name: 'resource', params: { resourceSlug: quiz.slug, moduleSlug: this.moduleSlug, pathwaySlug: this.pathwaySlug}});
    },
    navigateToModule() {
      this.$router.push({name: 'module', params: { moduleSlug: this.moduleSlug, pathwaySlug: this.pathwaySlug}});
    }
  }
}
</script>

<style scoped lang="scss">
.v-card__title {
  display: flex;

  h2 {
    flex: 1 1 0;
  }
}
</style>
