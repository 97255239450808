import Vue from 'vue';

export default (routeName) => (to, from, next) => {
  const authService = Vue.prototype.$auth;

  const fn = () => {
    if (authService.isAuthenticated) {
      return next();
    }

    next({ name: routeName, replace: true });
  }

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (!loading) {
      return fn();
    }
  })
};