<template>
  <div class="ma-home">
    <div class="ma-title">
      <h2>
        <Icon type="home" large light />
        Home
      </h2>
    </div>

    <StudentHome v-if="isStudent" />
    <TeacherHome v-if="isTeacher" />
    <PublicHome v-if="!isStudent && !isTeacher" />
  </div>
</template>

<script>
import Icon from "../components/Icon.vue";
import StudentHome from "./StudentHome.vue";
import TeacherHome from "./TeacherHome.vue";
import {mapActions} from "vuex";
import PublicHome from "./PublicHome.vue";

export default {
  name: "Home",
  components: {PublicHome, Icon, StudentHome, TeacherHome},
  methods: {
    ...mapActions('dashboard', ['loadData']),
  },
  computed: {
    isStudent() {
      return this.$auth.isStudent && !this.$auth.isTeacher;
    },
    isTeacher() {
      return this.$auth.isTeacher;
    }
  },
  async created() {
    await this.loadData();
  }
}
</script>