import Resource from "./Resource.js";
import {RESOURCE_TYPES} from "../constants";

class Pathway extends Resource {

  constructor(json = {}) {
    super(json);
    this.type = RESOURCE_TYPES.PATHWAY;
    this.metadata.sections = json.metadata?.sections || [];
    this.metadata.tags = json.metadata?.tags || [];
  }

  static fromList(list = []) {
    return list.map(json => new Pathway(json));
  }
}

export default Pathway;