import Vue from "vue";
import StudioApp from "./StudioApp.vue";
import VueHotkey from "v-hotkey";
import VueRouter from "vue-router";
import routes from "../routes/studio.js";
import { Auth0Plugin } from "../plugins/auth0.js";
import store from "../stores/studio";
import axios from "axios";
import Vuetify from "vuetify";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import Tracker from "../services/tracker.js";
import rg4js from 'raygun4js';


Vue.config.productionTip = false

// configure axios default behaviour
axios.defaults.headers.common['x-matt-component'] = "studio";
axios.defaults.headers.common['x-matt-app'] = "studio";


// configure raygun error tracking
rg4js('apiKey', 'iWTx4TVeP6dDp1mOuyIw');
rg4js('enableCrashReporting', true);
rg4js('enableRealUserMonitoring', true);
rg4js('excludedHostnames', ['localhost']);
rg4js('trackEvent', { type: 'pageView', path: location.pathname });
Vue.config.errorHandler = function (err, vm, info) { rg4js('send', { error: err, customData: [{info: info}] }) };


// configure vue router (with raygun tracking)
const router = new VueRouter({ routes });
Vue.use(VueRouter);


// add in hotkey plugin
Vue.use(VueHotkey);


// configure vuetify
Vue.use(Vuetify)
const opts = {
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#00bcd4',
        secondary: '#4f347c'
      }
    }
  }
}
const vuetify = new Vuetify(opts)


// configure auth0
const baseAppUrl = `${window.location.origin}/studio`;
Vue.use(Auth0Plugin, {
  store,
  domain: window.AUTH0_LOGIN_DOMAIN,
  clientId: window.AUTH0_LOGIN_CLIENT_ID,
  redirectUri: baseAppUrl,
  cacheLocation: 'localstorage',
  onRedirectCallback: () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('code');
    searchParams.delete('state');
    window.history.replaceState({}, document.title, `${window.location.pathname}${searchParams.toString()}`);
    router.push({name: 'home'});
  },
});


// create the vue instance
new Vue({
  vuetify,
  router,
  store,
  render: (createElement) => createElement(StudioApp),
}).$mount("#app");


// mvp translations
const translations = {
  "pathway": "section",
  "section": "module",
  "module": "unit"
}
Vue.prototype.PATHWAY = translations.pathway;
Vue.prototype.SECTION = translations.section;
Vue.prototype.MODULE = translations.module;
Vue.prototype.t = (key) => translations[key];


// configure custom engagement tracking
Tracker.$auth = Vue.prototype.$auth;
router.afterEach((to, from) => {
  rg4js('trackEvent', { type: 'pageView', path: to.path });
  if(!from.name || to.name === from.name) return;
  Tracker.leavePage(from);
});