<template>
  <v-app-bar app>
    <v-app-bar-nav-icon @click.stop="$emit('toggleDrawer')"></v-app-bar-nav-icon>
    <v-toolbar-title>MusicApprentice Studio</v-toolbar-title>

    <v-breadcrumbs v-if="showBreadcrumbs" :items="breadcrumbItems" large divider=">">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.to" exact>
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>

    <v-spacer></v-spacer>

    <v-menu left bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-if="canAccessCms" @click="navigateToCms">
          <v-list-item-title>CMS</v-list-item-title>
        </v-list-item>
        <v-list-item @click="navigateToGradus">
          <v-list-item-title>Gradus Software</v-list-item-title>
        </v-list-item>
        <v-list-item @click="navigateToFeedback">
          <v-list-item-title>Feedback</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!isAuthenticated" @click="doLogin">
          <v-list-item-title>Log in</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isAuthenticated" @click="doLogout">
          <v-list-item-title>Log out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import {mapState} from "vuex";
import PermissionsMixin from "../../common/permissions-mixin.js";

export default {
  name: "AppBar",
  mixins: [PermissionsMixin],
  methods: {
    navigateToGradus() {
      window.open('https://www.gradussoftware.com.au/', '_gradus');
    },
    navigateToCms() {
      window.open('/cms', '_cms');
    },
    navigateToFeedback() {
      this.$router.push({ name: 'feedback' });
    },
    doLogout() {
      this.$auth.logout({ returnTo: `${location.origin}${location.pathname}` });
    },
    doLogin() {
      this.$router.push({ name:'welcome' })
    },
  },
  computed: {
    ...mapState('ui', ['breadcrumbs']),
    breadcrumbItems() {
      const currentRouteIdx = this.breadcrumbs.findIndex(b => b.route.name === this.$route.name);
      return this.breadcrumbs.filter((b,idx) => idx <= currentRouteIdx).map(b => ({ text: b.title, to: b.route }));
    },
    routeMeta() {
      return this.$route?.meta || {};
    },
    showBreadcrumbs() {
      return this.routeMeta.showBreadcrumbs ?? true;
    }
  }
}
</script>
