import Vuex from 'vuex'
import Vue from "vue";
import {getWithId} from "../request.js";
import ui from "./ui.js";

Vue.use(Vuex);

const {MATT_USER_API} = window;

export default {
  namespaced: true,
  modules: { ui },
  state: {
    data: null,
    cohort: null,
    cohorts: [],
    cohortId: 'all',
    days: 7,
    hasStudents: null
  },
  mutations: {
    setData(state, data) {
      if(data.error) {
        state.data = null;
        state.hasStudents = false;
      } else {
        state.data = data;
        state.hasStudents = true;
      }
    },
    setCohortId(state, id) {
      state.cohortId = id;
      localStorage.setItem('teacherDashboard.cohortId', id);
    },
    setCohorts(state, cohorts) {
      state.cohorts = cohorts;
      if(!state.cohortId) {
        state.cohortId = cohorts[0].id;
        localStorage.setItem('teacherDashboard.cohortId', cohorts[0].id);
      }
    },
    setDays(state, days) {
      state.days = days;
      localStorage.setItem('teacherDashboard.days', days);
    },
    setCohort(state, cohort) {
      state.cohort = cohort;
      state.cohortId = cohort.id;
      localStorage.setItem('teacherDashboard.cohortId', cohort.id);
    },
  },
  getters: {
    studentName: (state) => (studentId) => {
      const student = state.cohort?.students.find(s => s.studentId === studentId) || "?";
      return student ? (student.name ?? student.profile?.name ?? "Student") : 'Student';
    },
    fromDate: (state) => {
      return new Date(Date.now() - 1000 * 60 * 60 * 24 * state.days);
    },
    toDate: () => new Date(),
  },
  actions: {
    async loadCohort({commit, state}, cohortId) {
      try {
        commit('ui/setLoading', 'Loading cohort...');
        const id = cohortId || state.cohortId || 'all';
        const url = `${MATT_USER_API}/teacher/me/cohorts/${id}`;
        const response = await getWithId(this._vm.$auth, url);
        commit('setCohort', response.data.cohort);
      } finally {
        commit('ui/setLoading');
      }
    },
    async loadCohorts({commit}) {
      try {
        commit('ui/setLoading', 'Loading cohorts...');
        const url = `${MATT_USER_API}/teacher/me/cohorts`;
        const response = await getWithId(this._vm.$auth, url);
        commit('setCohorts', response.data.cohorts);
      } finally {
        commit('ui/setLoading');
      }
    },
    async loadData({commit}, query) {
      try {
        commit('ui/setLoading', 'Loading dashboard data...');
        commit('setCohortId', query.cohortId);
        commit('setDays', query.days);

        const cohortResponse = await getWithId(this._vm.$auth, `${MATT_USER_API}/teacher/me/cohorts/${query.cohortId}`);
        commit('setCohort', cohortResponse.data.cohort);

        const url = `${MATT_USER_API}/teacher/report/all`;
        const response = await getWithId(this._vm.$auth, url, { params: query });

        commit('setData', response.data);
      } finally {
        commit('ui/setLoading');
      }
    },
  },
};
