class Resource {
  constructor(item) {
    this.id = item.id;
    this.slug = item.slug;
    this.title = item.title;
    this.description = item.description;
    this.type = item.type;
    this.state = item.state || "draft";
    this.visibility = item.visibility || "private";
    this.metadata = item.metadata || {};
    this.config = item.config || {};
    this.content = item.content || {};
    this.notes = item.notes || [];
    this.version = item.version;
    this.updatedAt = new Date(item.updatedAt);
    this.updatedBy = item.updatedBy;
    this.createdAt = new Date(item.createdAt);
    this.createdBy = item.createdBy;
    this.computed = item.computed || {}
  }
}

export default Resource;