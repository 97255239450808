<template>
  <div class="ma-content" v-if="!loading">
    <v-row v-if="showWelcomeCard">
      <v-col cols="12" md="6"><ContentCard :card="welcomeCard" /></v-col>
      <v-col cols="12" md="6"><ContentCard :card="whereToStartCard" /></v-col>
    </v-row>
    <v-row v-if="showNewsCards">
      <v-col cols="12" md="6"><ContentCard :card="musicNewsCard" /></v-col>
      <v-col cols="12" md="6"><ContentCard :card="teacherNewsCard" v-if="teacherNewsCard" /></v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import ContentCard from "../../resource/card/ContentCard.vue";

export default {
  name: "TeacherHome",
  components: {ContentCard},
  computed: {
    ...mapState('dashboard', ['data']),
    loading() {
      return !this.data;
    },
    showWelcomeCard() {
      return this.welcomeCard && this.whereToStartCard;
    },
    showNewsCards() {
      return (this.studentNewsCard || this.teacherNewsCard) && this.musicNewsCard;
    },
    welcomeCard() {
      return this.data.cards.welcome;
    },
    whereToStartCard() {
      return this.data.cards.whereToStart;
    },
    studentNewsCard() {
      return this.data.cards.studentNews
    },
    teacherNewsCard() {
      return this.data.cards.teacherNews;
    },
    musicNewsCard() {
      return this.data.cards.musicNews;
    },
  },
  methods: {
    ...mapActions('ui', ['resetBreadcrumbs']),
    ...mapActions('dashboard', ['loadData']),
  },
}
</script>

<style lang="scss">
@import '../style';

$block-title-color: lighten($col-home, 60%);

.ma-home {
  @include withTitleBlock($col-home);
}

</style>