import _ from "lodash";
import uuid from "uuid4";
import {postWithId} from "../stores/request.js";

const ONE_SECOND = 1000.0;
const ONE_MINUTE = 60 * ONE_SECOND;
const ONE_HOUR = 60 * ONE_MINUTE;

const MAX_QUEUE_SIZE = 10;
const QUEUE_FLUSH_INTERVAL_MS = 5 * ONE_SECOND;
const MAX_SESSION_IDLE_MS = ONE_HOUR;

export default class Tracker {

  static {
    // auth0 session, for posting events
    Tracker.$auth = null;

    // event queue, emptied every minute, or when it gets too big
    Tracker.events = [];

    // cached so we can calculate offsets
    Tracker.lastEventAt = {};

    // resume a current session on refresh
    Tracker.#setupSession();

    // record page leaving
    window.onbeforeunload = Tracker.#onBeforeUnload;

    // set up a flusher...
    setInterval(Tracker.#onTimerInterval, QUEUE_FLUSH_INTERVAL_MS);
  }

  static #onTimerInterval() {
    Tracker.#postEvents();
  }

  static #onBeforeUnload() {
    Tracker.#addEvent({ type: 'pageLeave' });
    Tracker.#postEvents();
  }

  static #setupSession() {
    if(Tracker.#canContinueLastSession) {
      Tracker.#continueSession();
    } else {
      Tracker.#createNewSession();
    }
  }

  static get #sessionLastActiveAt() {
    return new Date(localStorage.getItem('sessionActiveAt') || 0);
  }

  static get #sessionIdleTime() {
    return new Date() - Tracker.#sessionLastActiveAt;
  }

  static get #lastSessionId() {
    return localStorage.getItem('sessionId') || null;
  }

  static get #canContinueLastSession() {
    return Tracker.#lastSessionId && Tracker.#sessionIdleTime < MAX_SESSION_IDLE_MS;
  }

  static #updateSessionLastActiveAt() {
    localStorage.setItem('sessionActiveAt', new Date().toISOString());
  }

  static #createNewSession() {
    Tracker.sessionId = uuid();
    Tracker.createdAt = new Date();

    localStorage.setItem('sessionId', Tracker.sessionId);
    localStorage.setItem('sessionStart', Tracker.createdAt.toISOString());
    localStorage.setItem('sessionActiveAt', Tracker.createdAt.toISOString());

    Tracker.#addEvent({ type: 'sessionStart' });
  }

  static #continueSession() {
    const lastSessionStart = localStorage.getItem('sessionStart');
    const lastSessionActiveAt = localStorage.getItem('sessionActiveAt');

    Tracker.sessionId = Tracker.#lastSessionId;
    Tracker.createdAt = new Date(lastSessionStart || lastSessionActiveAt);
  }

  static #addEvent(event) {
    const sessionIdleTime = event.createdAt - Tracker.#sessionLastActiveAt;
    if(sessionIdleTime > MAX_SESSION_IDLE_MS) {
      Tracker.#createNewSession();
    }

    event.sessionId = Tracker.sessionId;
    event.createdAt = new Date();
    event.sessionOffset = (event.createdAt - Tracker.createdAt) / ONE_SECOND;
    event.lastEventOffset = (event.createdAt - Tracker.#findLastEventAt(event.type)) / ONE_SECOND;

    Tracker.events.push(event);
    Tracker.lastEventAt[Tracker.#isPageType(event.type) ? 'page' : event.type] = event.createdAt;
    Tracker.#updateSessionLastActiveAt();

    // flush the queue if it's too big (don't await it)
    if(Tracker.events.length > MAX_QUEUE_SIZE) {
      Tracker.#postEvents();
    }
  }
  
  static #findLastEventAt(type) {
    let _type = type;
    if(Tracker.#isPageType(type)) {
      _type = 'page';
    }
    return Tracker.lastEventAt[_type] || new Date();
  }
  
  static #isPageType(type) {
    return ['page', 'slide', 'question'].includes(type);
  }

  static async #postEvents() {
    if(!Tracker.events.length || !Tracker.isAuthenticated()) {
      return;
    }

    const events = [...Tracker.events];
    Tracker.events = [];

    try {
      await postWithId(Tracker.$auth, `${window.MATT_USER_API}/events`, {events});
    } catch(error) {
      // don't really care here, it will eventually be reattempted
    }
  }

  static isAuthenticated() {
    return Tracker.$auth?.isAuthenticated === true;
  }

  static leaveQuestion(data) {
    Tracker.#addEvent({
      type: 'question',
      data: {
        ..._.omit(Tracker.$route, ['matched', 'meta', 'hash']),
        ...data
      }
    });
  }

  static leaveSlide(data) {
    Tracker.#addEvent({
      type: 'slide',
      data: {
        ..._.omit(Tracker.$route, ['matched', 'meta', 'hash']),
        ...data
      }
    });
  }

  static leavePage(route) {
    Tracker.#addEvent({
      type: 'page',
      data: _.omit(route, ['matched', 'meta', 'hash'])
    });
  }
}

