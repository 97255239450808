import Vuex from 'vuex'
import Vue from "vue";
import {getWithId, putWithId} from "../request.js";
import ui from "./ui.js";

Vue.use(Vuex);

const {MATT_USER_API} = window;

export default {
  namespaced: true,
  modules: { ui },
  state: {
    data: null,
    noobMode: false,
  },
  mutations: {
    setData(state, data) {
      state.data = data;
      state.noobMode = data.noob;
    },
  },
  actions: {
    async dismissDiagnosticCard() {
      const url = `${MATT_USER_API}/userPreference?hideDiagnosticCard=true`;
      await putWithId(this._vm.$auth, url);
    },
    async loadData({commit}) {
      try {
        commit('ui/setLoading', 'Loading dashboard data...');
        const url = `${MATT_USER_API}/dashboard`;
        const response = await getWithId(this._vm.$auth, url);
        commit('setData', response.data);
      } finally {
        commit('ui/setLoading');
      }
    },
  },
};
