const PermissionsMixin = {
  computed: {
    isAuthenticated() {
      return !!this.$auth?.isAuthenticated;
    },
    isTeacherOrStudent() {
      return this.isStudent || this.isTeacher;
    },
    isTeacher() {
      return !!this.$auth?.isTeacher;
    },
    isStudent() {
      return !!this.$auth?.isStudent;
    },
    doesManageSystemStuff() {
      return this.canManagerUsers || this.canManageSubscriptions
    },
    canManagerUsers() {
      return !!this.$auth?.canManagerUsers;
    },
    canManageContent() {
      return !!this.$auth?.canManageContent;
    },
    canAccessStudio() {
      return !!this.$auth?.canAccessStudio;
    },
    canAccessCms() {
      return !!this.$auth?.canAccessCms;
    },
    canManageSubscriptions() {
      return !!this.$auth?.canManageSubscriptions;
    }
  }
}

export default PermissionsMixin;