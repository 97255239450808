import Vue from "vue";
import createAuth0Client from "@auth0/auth0-spa-js";
import jwtDecode from 'jwt-decode';
import rg4js from "raygun4js";

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

let instance;

const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
const useAuth0 = ({
                    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
                    redirectUri = window.location.origin,
                    store,
                    ...options
                  }) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        error: null,
        permissions: []
      };
    },
    computed: {
      isTeacher() {
        return this.permissions.includes('manage:students');
      },
      isStudent() {
        return this.permissions.includes('manage:teachers');
      },
      isSubscribed() {
        return this.isStudent || this.isTeacher;
      },
      canManagerUsers() {
        return this.permissions.includes('manage:users');
      },
      canManageContent() {
        return this.permissions.includes('write:resource');
      },
      canManageSubscriptions() {
        return this.permissions.includes('manage:subscriptions');
      },
      canAccessStudio() {
        return this.permissions.includes('access:studio');
      },
      canAccessCms() {
        return this.permissions.includes('access:cms');
      }
    },
    methods: {
      async onLoggedIn() {
        await store.dispatch('app/initialise', { auth: this });

        rg4js('setUser', {
          identifier: this.user.sub,
          isAnonymous: false,
          email: this.user.email,
          firstName: this.user?.['https://musicapprentice.com.au/userdata']?.student?.firstName || null,
          fullName: this.user.name,
        });

        const { appName, apiVersion, apiEnv, appVersion, appEnv } = store.state.app;
        rg4js('withTags', [appName, `API_${apiEnv}_${apiVersion}`, `APP_${appEnv}_${appVersion}`]);
      },
      async onLoggedOut() {
      },
      async doLogin(options = {}) {
        await this.loginWithRedirect(options);
      },
      async loginWithRedirect(options = {}) {
        try {
          await this.auth0Client.loginWithRedirect(options);
        } catch (e) {
          this.error = e;
          // eslint-disable-next-line
          console.error(e);
        }
      },
      async getDetailedTokenSilently() {
        const options = {
          detailedResponse: true,
          audience: window.AUTH0_AUDIENCE,
        };

        return this.getTokenSilently(options)
      },
      async getTokenSilently(options) {
        // note: this is sometimes called before the auth0client is initialised in the async created block
        while(!this.auth0Client) {
          await wait(250);
        }

        try {
          const token = await this.auth0Client.getTokenSilently(options);
          token.type = 'bearer';

          const decoded = jwtDecode(token.access_token);
          this.permissions = decoded.permissions;
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = await this.auth0Client.isAuthenticated();

          return token;
        } catch(err) {
          if (err.error === 'consent_required') {
            this.auth0Client.loginWithRedirect({...options, prompt: 'consent', display: 'page'});
            return { message: "reattempting login" };
          }
        }
      },
      async invalidateToken() {
        await this.auth0Client.cacheManager.clear(options.clientId);
      },
      async forceTokenRefresh() {
        await this.invalidateToken();
        await this.getDetailedTokenSilently();
      },
      logout(o) {
        return this.auth0Client.logout(o);
      }
    },
    watch: {
      async user(newUser, oldUser) {
        if(newUser?.sub !== oldUser?.sub) {
          if(newUser.sub) {
            await this.onLoggedIn();
          } else {
            await this.onLoggedOut();
          }
        }
      }
    },
    async created() {
      // initialise the auth0 client
      try {
        const auth0ClientOptions = { ...options, client_id: options.clientId, redirect_uri: redirectUri };
        this.auth0Client = await createAuth0Client(auth0ClientOptions);
      } catch(err) {
        console.log("could not create auth0 client")
        console.error(err);
      }

      // check if this is OAuth redirect
      try {
        if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
          const {appState} = await this.auth0Client.handleRedirectCallback();
          this.error = null;
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = await this.auth0Client.getUser();

        if (this.user?.['https://musicapprentice.com.au/userdata']?.student?.firstName) {
          const student = this.user['https://musicapprentice.com.au/userdata'].student;
          this.user.name = `${student.firstName} ${student.lastName}`;
        } else if (this.user?.['https://musicapprentice.com.au/userdata']?.account?.name) {
          this.user.name = this.user['https://musicapprentice.com.au/userdata'].account.name;
        }

        this.loading = false;
      }
    }
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  }
};