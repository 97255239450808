import Vuex from 'vuex'
import Vue from "vue";
import {getWithId} from "../request.js";
import Pathway from "../../models/Pathway.js";
import profile from "./profile.js";
import ui from "./ui.js";
import teacher from "./teacher.js";
import tasks from "./tasks.js";
import results from "./results.js";
import categoryBrowser from "./categoryBrowser.js";
import dashboard from "./dashboard.js";
import speech from "../common/speech.js";
import teacherDashboard from "./teacherDashboard.js";
import app from "./app";

Vue.use(Vuex);

const {MATT_CONTENT_API, MATT_USER_API} = window;

export default new Vuex.Store({
  modules: {
    profile,
    ui,
    teacher,
    tasks,
    results,
    categoryBrowser,
    dashboard,
    teacherDashboard,
    speech,
    app
  },
  state: {
    pathwayCache: {},
    pathways: [],
    pathway: null,
    pathwayProgress: null,
    module: null,
    moduleProgress: null,
    resource: null,
    tags: null
  },
  getters: {
    moduleTitle(state) {
      if(!state.module) return "";
      let title = state.module.title;
      if(state.module.level) title = `${title} (${state.module.level})`;
      return title;
    }
  },
  mutations: {
    setPathway(state, data) {
      state.pathway = new Pathway(data);
    },
    setModule(state, data) {
      state.module = data;
    },
    setResource(state, data) {
      state.resource = data;
    },
    setModuleProgress(state, data) {
      state.moduleProgress = data;
    },
    setPathwayProgress(state, data) {
      state.pathwayProgress = data;
    },
    setPathways(state, data) {
      state.pathways = data;
    },
    setTags(state, data) {
      state.tags = data;
    },
  },
  actions: {
    async resetResource({commit}) {
      commit('setResource', null);
    },
    async loadResource({commit, dispatch}, slug) {
      await dispatch('ui/setLoading', 'Loading...');

      try {
        const url = `${MATT_CONTENT_API}/resources/${slug}`;
        const response = await getWithId(this._vm.$auth, url);
        const data = response.data;
        commit('setResource', data);
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async loadQuiz({commit, dispatch}, slug) {
      await dispatch('ui/setLoading', 'Loading...');

      try {
        const url = `${MATT_CONTENT_API}/quiz/${slug}`;
        const response = await getWithId(this._vm.$auth, url);
        const data = response.data;
        commit('setResource', data);
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async loadDiagnosticProgress({dispatch}, quizId) {
      await dispatch('ui/setLoading', 'Loading quiz progress...');
      try {
        const url = `${MATT_USER_API}/submissions/diagnosticProgress/${quizId}`;
        const response = await getWithId(this._vm.$auth, url);
        return response.data;
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async loadModule({commit, dispatch}, slug) {
      await dispatch('ui/setLoading', 'Loading...');

      try {
        const url = `${MATT_CONTENT_API}/pathway/module/${slug}`;
        const response = await getWithId(this._vm.$auth, url);
        const data = response.data;
        commit('setModule', data);
      }  finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async loadModuleProgress({commit, state, dispatch}) {
      await dispatch('ui/setLoading', 'Loading...');

      try {
        const resourceIds = [
          ...(state.module?.computed.gradedResources ?? []),
          ...(state.module?.computed.extraResources ?? []),
          ...(state.module?.computed.practiceResources ?? []),
          ...(state.module?.computed.tutorialResources ?? []),
        ].map(r => r.id).join(',');

        const params = {resourceIds};
        const response = await getWithId(this._vm.$auth, `${MATT_USER_API}/report/resources/myProgress`, {params});
        commit('setModuleProgress', response.data);
        return response.data;
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async getTags({dispatch}) {
      if(!this.state.tags) {
        await dispatch('loadTags');
      }
    },
    async loadTags({commit, dispatch}) {
      await dispatch('ui/setLoading', 'Loading tags...');

      try {
        const url = `${MATT_CONTENT_API}/tags`;
        const response = await getWithId(this._vm.$auth, url, {params:{reportable:true}});
        const data = response.data;
        commit('setTags', data);
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async getPathways({state, dispatch}) {
      if(state.pathways.length === 0) {
        await dispatch('loadPathwaysWithStatus');
      }
    },
    async refreshPathways({dispatch}) {
      await dispatch('loadPathwaysWithStatus');
    },
    async loadPathwaysWithStatus({commit, dispatch}) {
      await dispatch('ui/setLoading', 'Loading pathways...');

      try {
        const url = `${MATT_USER_API}/pathways/me`;
        const response = await getWithId(this._vm.$auth, url);
        const data = response.data.pathways;
        commit('setPathways', data);
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async getPathway({state, commit, dispatch}, slug) {
      if(state.pathwayCache[slug]) {
        const pathway = state.pathwayCache[slug];
        commit('setPathway', pathway);
        return pathway;
      }

      await dispatch('loadPathway', slug);
    },
    async loadPathway({commit, dispatch}, slug) {
      await dispatch('ui/setLoading', 'Loading...');

      try {
        window.localStorage.setItem('selectedPathway', slug);
        const url = `${MATT_CONTENT_API}/pathway/${slug}`;
        const response = await getWithId(this._vm.$auth, url);
        const data = response.data;
        commit('setPathway', data);
      } finally {
        await dispatch('ui/setLoading', null);
      }
    },
    async loadPathwayProgress({commit, dispatch}, slug) {
      await dispatch('ui/setLoading', 'Loading...');

      try {
        const url = `${MATT_USER_API}/pathway/${slug}/me`;
        const response = await getWithId(this._vm.$auth, url);
        const data = response.data;
        commit('setPathwayProgress', data);
      } finally {
        await dispatch('ui/setLoading', null);
      }
    }
  },
});
