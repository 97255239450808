<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: "StudioApp",
};
</script>

<style lang="scss">
@import "../assets/studio/main";
</style>