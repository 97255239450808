<template>
  <div v-if="hasResource">
    <v-btn v-show="hasSpeech && showPlay" fab height="24" width="24" @click="doPlay" color="secondary" :loading="isLoading">
      <v-icon x-small>mdi-account-voice</v-icon>
    </v-btn>
    <v-btn v-show="!hasSpeech && showPlay" fab height="24" width="24" @click="doGenerate" color="secondary" :loading="isLoading">
      <v-icon x-small>mdi-account-voice</v-icon>
    </v-btn>
    <v-btn v-show="showStop" fab height="24" width="24" @click="doPause" color="secondary" :loading="isLoading">
      <v-icon x-small>mdi-pause</v-icon>
    </v-btn>
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import _ from "lodash";

export default {
  name: "SpeechPlayerController",
  props: {
    resource: {
      type: Object,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    }
  },
  methods: {
    ...mapActions('speech', ['generateAndPlaySpeech', 'playSpeech', 'stopSpeech', 'pauseSpeech']),
    async doPlay() {
      const options = {speechUrl: this.speechUrl, resourceId: this.resource.id, field: this.field};
      await this.playSpeech(options);
    },
    async doPause() {
      await this.pauseSpeech();
    },
    async doGenerate() {
      await this.generateAndPlaySpeech({resourceId: this.resource.id, field: this.field, options: this.options});
    }
  },
  computed: {
    ...mapState('speech', ['speechState']),
    hasResource() {
      return !_.isEmpty(this.resource);
    },
    speechUrl() {
      return this.resource?.config?.speech?.[this.field]?.url;
    },
    hasSpeech() {
      return !_.isEmpty(this.speechUrl);
    },
    myId() {
      return `${this.resource.id}|${this.field}`;
    },
    isLoading() {
      return this.speechState === `loading|${this.myId}`;
    },
    isPlaying() {
      return this.speechState === `playing|${this.myId}`;
    },
    showPlay() {
      return !this.isPlaying;
    },
    showStop() {
      return this.isPlaying;
    },
  },
}

</script>

<style scoped lang="scss">
</style>
