<template>
  <v-card elevation="5">
    <v-card-title>
      <h2 @click="navigateToWord" class="ma-clickable">Word of the day</h2>
      <SpeechPlayerController :resource="page" field="description" />
    </v-card-title>
    <v-card-text @click="navigateToWord" class="ma-clickable">
      <h3>{{ page.title }}</h3>
      <div v-html="content"></div>
      <span class="ma-help-text pa-2">Click to learn more...</span>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapState} from "vuex";
import SpeechPlayerController from "../../common/SpeechPlayerController.vue";

export default {
  name: "WordOfTheDayCard",
  components: {SpeechPlayerController},
  computed: {
    ...mapState('dashboard', ['data', 'noobMode']),
    loading() {
      return this.data?.wordOfTheDay;
    },
    page() {
      return this.data.wordOfTheDay.resource;
    },
    title() {
      return this.page.title;
    },
    content() {
      return this.page.description;
    }
  },
  methods: {
    navigateToWord() {
      this.$router.push({name: 'page', params: { resourceSlug: this.page.slug }});
    }
  }
}
</script>

<style scoped lang="scss">
.v-card__title {
  display: flex;

  h2 {
    flex: 1 1 0;
  }
}
</style>
