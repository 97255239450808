<template>
  <v-icon class="ma-icon" :class="classes" :large="large" :small="small" :color="color">{{ icon }}</v-icon>
</template>

<script>
import {Icons} from "../../../constants";

export default {
  name: "Icon",
  props: {
    large: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "inherit"
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    classes() {
      return this.light ? "ma-icon-light" : "ma-icon-dark";
    },
    icon() {
      return Icons[this.type] ?? "mdi-close-circle-outline";
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../style";

.ma-icon {
  &-dark {
    color: $text-dark;
  }

  &-light {
    color: $text-light;
  }
}

</style>