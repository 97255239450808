<template>
  <div class="seeker">
    <audio ref="audio" :src="speechUrl" />
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import MediaLog from "../../utils/media-log.js";

export default {
  name: "SpeechPlayer",
  data() {
    return {
      audioPlayer: null,
      state: null,
      prePauseState: null,
      currentTime: 0,
      duration: 0,
      playOnReady: false,
    }
  },
  computed: {
    ...mapState('speech', ['speechUrl', "speechState"]),
  },
  methods: {
    ...mapActions('speech', ['playSpeech', 'stopSpeech']),
  },
  watch: {
    speechUrl() {
      this.audioPlayer.src = this.speechUrl;
      this.state = "loading";
    },
    speechState(newVal, oldVal) {
      if(newVal === oldVal) return;

      console.log('speechState', oldVal, '==>', newVal)

      if (newVal.startsWith("playing|") && oldVal === 'paused') {
        this.audioPlayer.play();
        this.prePauseState = null;
      } else if (newVal.startsWith("playing|")) {
        if(this.state === 'ready') {
          this.audioPlayer.play();
          this.playOnReady = false;
        } else {
          this.playOnReady = true;
        }
      } else if(newVal === "stopped") {
        this.audioPlayer.pause();
        this.playOnReady = false;
      } else if(newVal === 'paused') {
        this.prePauseState = oldVal;
        this.audioPlayer.pause();
      }
    }
  },
  mounted() {
    this.audioPlayer = this.$refs.audio;

    this.audioPlayer.addEventListener("timeupdate", () => {
      this.currentTime = this.audioPlayer.currentTime;
    });

    this.audioPlayer.addEventListener("play", () => {
      this.state = 'playing';
      MediaLog.log(this.$auth, this.speechUrl, 'play', {});
    });

    this.audioPlayer.addEventListener("ended", () => {
      this.state = 'ready';
      MediaLog.log(this.$auth, this.speechUrl, 'completed', {});
      this.stopSpeech();
    });

    this.audioPlayer.addEventListener("durationchange", () => {
      this.duration = this.audioPlayer.duration;

      if(this.playOnReady) {
        this.audioPlayer.play();
        this.playOnReady = false;
      }

      MediaLog.log(this.$auth, this.speechUrl, 'loaded', {});
    });

    this.audioPlayer.addEventListener("canplaythrough", () => {
      this.state = (this.state === 'playing' ? 'playing' : 'ready');
    });
  }
}

</script>

<style scoped lang="scss">
.seeker {
  display: none;
}
</style>
