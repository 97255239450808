import Vuex from 'vuex'
import Vue from "vue";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    snackbarVisible: false,
    snackbarColor: 'success',
    snackbarMessage: '',
    snackbarTimeout: 3000,
    popupVisible: false,
    popupMessage: null,
    breadcrumbs: [],
    loading: false,
    alertVisible: false,
    alertMessage: null,
    alertTitle: null,
  },
  mutations: {
    setSnackbar(state, data) {
      state.snackbarColor = data.color || state.snackbarColor;
      state.snackbarMessage = data.message;
      state.snackbarTimeout = data.timeout || 3000;
      state.snackbarVisible = data.visible ?? true;
    },
    resetBreadcrumbs(state) {
      state.breadcrumbs = [];
    },
    pushBreadcrumb(state, breadcrumb) {
      state.breadcrumbs.push(breadcrumb);
    },
    popBreadcrumb(state) {
      return state.breadcrumbs.pop();
    },
    setLoading(state, msg) {
      if(msg) {
        state.popupVisible = true;
        state.popupMessage = msg;
        state.loading = false;
      } else {
        state.popupVisible = false;
        state.popupMessage = null;
        state.loading = false;
      }
    },
    setAlert(state, msg) {
      state.alertVisible = msg.visible;
      state.alertMessage = msg.content;
      state.alertTitle = msg.title
    }
  },
  actions: {
    popupAlert({commit}, msg) {
      commit('setAlert', { ...msg, visible: true });
    },
    closeAlert({commit}) {
      commit('setAlert', {visible: false, content: null, title: null});
    },
    showSnack({commit}, data) {
      commit('setSnackbar', { ...data, visible: true });
    },
    showSuccess({dispatch}, message) {
      dispatch('showSnack', {
        color: 'success',
        timeout: 1500,
        message,
      });
    },
    showError({dispatch}, message) {
      dispatch('popupAlert', { title: "Error", content: message });
    },
    pushBreadcrumb({commit}, { title, route }) {
      commit('pushBreadcrumb', { title, route });
    },
    popBreadcrumb({commit}) {
      commit('popBreadcrumb');
    },
    resetBreadcrumbs({commit}, { title, route }) {
      commit('resetBreadcrumbs');
      commit('pushBreadcrumb', { title, route });
    },
    clearBreadcrumbs({commit}) {
      commit('resetBreadcrumbs');
    },
    setLoading({commit}, message) {
      commit('setLoading', message)
    }
  },
};
