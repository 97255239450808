import Vuex from 'vuex'
import Vue from "vue";
import {getWithId} from "../request.js";
import ui from "./ui.js";

const {MATT_USER_API} = window;

Vue.use(Vuex);

export default {
  namespaced: true,
  modules: { ui },
  state: {
    myTasks: [],
    myTaskProgress: [],
  },
  mutations: {
    setMyTasks(state, data) {
      state.myTasks = data;
    },
    setMyTaskProgress(state, data) {
      state.myTaskProgress = data;
    },
  },
  actions: {
    async loadMyTasks({commit, dispatch}) {
      await dispatch('ui/setLoading', 'Loading my tasks...');

      try {
        const response = await getWithId(this._vm.$auth, `${MATT_USER_API}/tasks/me`);
        commit('setMyTasks', response.data);
        return response.data;
      } finally {
        commit('ui/setLoading', null);
      }
    },
    async loadMyTaskProgress({commit, state, dispatch}) {
      await dispatch('ui/setLoading', 'Loading my progress...');

      try {
        const params = {
          resourceIds: state.myTasks.map(t => t.resourceId).join(',')
        };
        const response = await getWithId(this._vm.$auth, `${MATT_USER_API}/report/resources/myProgress`, {params});
        commit('setMyTaskProgress', response.data);
        return response.data;
      } finally {
        commit('ui/setLoading', null);
      }
    }
  },
};
