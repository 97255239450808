<template>
  <v-card elevation="5">
    <v-card-title>
      <h2 v-text="title" />
      <SpeechPlayerController :resource="card" field="content.html" />
    </v-card-title>

    <v-card-text>
      <div v-html="content"/>
    </v-card-text>
  </v-card>
</template>

<script>

import SpeechPlayerController from "../../common/SpeechPlayerController.vue";

export default {
  name: "ContentCard",
  components: {SpeechPlayerController},
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      return this.card.title;
    },
    content() {
      return this.card.content.html;
    },
    speechUrl() {
      return this.card.content.speechUrl;
    },
  },
}
</script>

<style scoped lang="scss">
.v-card__title {
  display: flex;

  h2 {
    flex: 1 1 0;
  }
}

</style>