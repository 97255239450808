import Vuex from 'vuex'
import Vue from "vue";
import {getWithId} from "../request";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    navBarContent: {},
    appName: "MusicApprentice",
    apiVersion: "",
    apiEnv: "",
    appVersion: window.MATT_APP_VERSION,
    appEnv: window.MATT_APP_ENV,
    pathways: [],
  },
  mutations: {
    setData(state, data) {
      state.appName = data.appName;
      state.apiEnv = data.apiEnv;
      state.apiVersion = data.apiVersion;
      state.navBarContent = data.navBarContent;
      state.pathways = data.pathways;
    },
  },
  actions: {
    async initialise({commit}, options) {
      const auth = options.auth || this.$auth;
      const response = await getWithId(auth, `${window.MATT_USER_API}/appData`);
      commit('setData', response.data.data);
    }
  },
};
