export const getCategoryPath = (category) => {
  if(!category) {
    return '/';
  }

  return category.path === '/'
    ? '/' + category.id.substring(0, 8)
    : category.path + '/' + category.id.substring(0, 8);
}

export const getCategoryPathArr = (category) => {
  if(!category) {
    return [];
  }

  const path = category.metadata.path.substr(1).split('/').filter(x=>x);
  const id = category.id.substring(0, 8);
  return path.concat(id);
}